import axios from 'axios';

const staging = 'https://quiet-springs-77620.herokuapp.com';
const prod = 'https://limitless-sands-30505.herokuapp.com';
const test = 'http://localhost:5000';
const url = prod;

export const getMultiSpecies = (page,searchQuery) => axios.get(`${url}/species/?page=${page}&searchQuery=${searchQuery}`);
export const getOneSpecies = (id) => axios.get(`${url}/species/${id}`);

export const getEvents = (page,taxonKey) => axios.get(`${url}/events/?species=${taxonKey}&page=${page}`);
export const getEvent = (id) => axios.get(`${url}/events/${id}`);

export const getHotspots = (page, taxonKey) => axios.get(`${url}/hotspots/?species=${taxonKey}&page=${page}`);
export const getHotspot = (id) => axios.get(`${url}/hotspots/${id}`);