// src/MapComponent.js
import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, useGoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import Chart from 'chart.js/auto'; // This is needed for the Line import
import { Line } from "react-chartjs-2";

// Define the initial center and zoom level for the map
// const center = { lat: 34.975662, lng: -97.761300 };
const zoom = 9;


const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const containerStyle = {
  width: '100%',
  height: '400px'
};

const MapComponent = ({locations, month_dist, center}) => {

  const calendar_data = {
    labels: labels,
    datasets: [
      {
        label: "Species Occurence Seasonality", // TODO: Add custom tooltip
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: month_dist,
      },
    ],
  };
  const max_value =  Math.max(...month_dist);
  const default_options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Sightings',
        },
        beginAtZero: true, // Ensure the y-axis starts at zero
        min: 0,         // Set the minimum value for the y-axis
        max: max_value * 1.1,         // Set the maximum value for the y-axis
      },
    },
  };

  const [options, setOptions] = useState(default_options);
  const [map, setMap] = useState(null);
  const [chartData, setChartData] = useState(calendar_data);
  const [viewportMarkers, setViewportMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);

  // Function to get airports in the viewport
  const getAirportsInViewport = (bounds) => {
    if (!bounds) return [];
    const selected = [];
    locations.forEach(location => {
      const position = new window.google.maps.LatLng(location.coordinates[0], location.coordinates[1]);
      if (bounds.contains(position)) {
        selected.push(location);
      }
    });
    return selected;
  };

  // Handle map load
  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  // Handle viewport change
  const onBoundsChanged = () => {
    const bounds = map.getBounds();
    if (!bounds) return;

    // Clear previous markers
    viewportMarkers.forEach(marker => marker.setMap(null));
    setViewportMarkers([]);

    // Add new markers
    var updated_month_dist = new Array(12).fill(0);
    const airportsInViewport = getAirportsInViewport(bounds);
    const newMarkers = airportsInViewport.map((location, index) => {
      const marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(location.coordinates[0], location.coordinates[1]),
        map: map
      });

      // marker.addListener('click', () => {
      //   setSelectedMarker({
      //     position: marker.getPosition(),
      //     content: `Count: ${location.count}`
      //     // content: `<b>Code: </b>${airport.Code}<br/><b>NAME: </b>${airport.Name}<br/><b>CITY: </b>${airport.City}<br/><b>State: </b>${airport.State}<br/><b>COUNTRY: </b>${airport.Country}`
      //   });
      //   map.panTo(marker.getPosition());
      //   marker.setAnimation(window.google.maps.Animation.BOUNCE);
      //   setTimeout(() => marker.setAnimation(null), 1250);
      // });
      updated_month_dist = location.month_dist.map((value, index) => value + updated_month_dist[index]);
      const updated_data = {
        labels: labels,
        datasets: [
          {
            label: "Species Occurence Seasonality", // TODO: Add custom tooltip
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: updated_month_dist,
          },
        ],
      };
      setChartData(updated_data);
      return marker;
    });

    setViewportMarkers(newMarkers);
  };

  useEffect(() => {
    if (map) {
      window.google.maps.event.addListener(map, 'idle', onBoundsChanged);
    }
  }, [map]);

  return (
    <LoadScript>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={3}
        onLoad={onLoad}
        onBoundsChanged={onBoundsChanged}
      >
        {selectedMarker && (
          <InfoWindow position={selectedMarker.position}>
            <div dangerouslySetInnerHTML={{ __html: selectedMarker.content }} />
          </InfoWindow>
        )}
      </GoogleMap>
      <Line data={chartData} options={options}/>
    </LoadScript>
  );
};

export default MapComponent;

// Try to recreate to look like this: https://mycomap.com/taxonomy/4-basidiomycota/