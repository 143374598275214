import React, { useEffect } from 'react';
import { Container, Grow, Grid, Paper } from '@material-ui/core';

import HotspotsPagination from './HotspotsPagination';
import { Hotspots } from "./Hotspots";
import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';

import { getHotspots } from '../../actions/hotspots';


function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export const HotspotsPage = (props) => {
    const query = useQuery();
    const page = query.get('page') || 1;

    const dispatch = useDispatch();

    // Only run once, pagination page will take over once vars are populated
    useEffect(() => {
        dispatch(getHotspots(page));
    }, [page, dispatch]);

    return (
        <Grow in>
            <Container>
                <Grid container justifyContent="space-between" alignItems="stretch" spacing={3}>
                    <Grid item xs={12} sm={10}>
                        <Hotspots />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Paper elevation={6}>
                            <HotspotsPagination page={page} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Grow>
    )
}

// TODO: Add a dynamic map component which shows only visible markers on a graph
// import React, { useState, useEffect } from 'react';
// import { GoogleMap, Marker, InfoWindow } from 'react-google-maps';

// const MapWithVisibleMarkers = ({ markers }) => {
//   const [visibleMarkers, setVisibleMarkers] = useState([]);

//   // Function to determine visible markers based on map bounds
//   const handleMapChanged = (map) => {
//     if (!map) return;

//     const bounds = map.getBounds();
//     const visibleMarkers = markers.filter(marker =>
//       bounds.contains(marker.position)
//     );

//     setVisibleMarkers(visibleMarkers);
//   };

//   // useEffect to initialize visible markers on load and handle map changes
//   useEffect(() => {
//     const initialVisibleMarkers = markers.filter(marker =>
//       map.getBounds().contains(marker.position)
//     );
//     setVisibleMarkers(initialVisibleMarkers);

//     // Event listener for map changes
//     map.addListener('bounds_changed', () => handleMapChanged(map));

//     return () => {
//       // Clean up event listener
//       map.removeListener('bounds_changed', handleMapChanged);
//     };
//   }, []);

//   return (
//     <div style={{ display: 'flex', flexDirection: 'row' }}>
//       <div style={{ flex: '1', height: '400px' }}>
//         <GoogleMap
//           defaultZoom={8}
//           defaultCenter={{ lat: 37.7749, lng: -122.4194 }}
//           onUnmount={handleMapChanged} // Ensure map changes trigger re-evaluation of visible markers
//         >
//           {markers.map((marker, index) => (
//             <Marker
//               key={index}
//               position={marker.position}
//             />
//           ))}
//         </GoogleMap>
//       </div>
//       <div style={{ flex: '1', height: '400px', overflowY: 'scroll' }}>
//         <h3>Visible Markers:</h3>
//         <ul>
//           {visibleMarkers.map((marker, index) => (
//             <li key={index}>
//               Marker {index + 1}: {marker.name} ({marker.position.lat()}, {marker.position.lng()})
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default MapWithVisibleMarkers;
